@import "../../../assets/styles/styles.scss";

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    // -webkit-backdrop-filter: blur($section-blur);
    // backdrop-filter: blur($section-blur);

    &__top {
        height: $aboutMeTitle-font-size;
        padding: 10px 0 5px 0;
        // background-color: pink;
        width: 100%;
    }

    &__title {
        font-size: calc($aboutMeTitle-font-size * 0.5);
        color: $color-title;
        font-weight: 200;
        text-align: center;
    }

    &__charts {
        width: 100%;
        height: 67%;
        text-align: center;
        display: grid;

        grid-template-columns: repeat(
            auto-fit,
            minmax(30%, 1fr)
        ); //Alter the minmax value to adjust the grid size/distribution
        grid-template-rows: repeat(auto-fit, minmax(20%, 1fr));
        justify-items: center;
        align-items: center;
    }
}

@media screen and (min-width: 768px) {
    .skills {
        &__charts {
            width: 80%;
        }
    }
}
