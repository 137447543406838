@import "../../assets/styles/styles.scss";

.about-me {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    scroll-snap-align: start;
    overflow-y: hidden;
    overflow-x: hidden;
    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: aqua;
        z-index: 2;
        padding: 10px 0 2px 0;
        font-size: $aboutMeTitle-font-size;
        color: $color-title;
        font-weight: 200;
        text-align: center;
        // background-color: brown;

        &__main {
            text-decoration: underline;
            text-decoration-thickness: 1px;
        }

        &__arrow {
            cursor: pointer;
            font-size: calc($aboutMeTitle-font-size * 0.7);
            opacity: 20%;
            margin-left: 2%;
            margin-right: 2%;
        }

        &__prompt {
            white-space: nowrap;
            text-decoration-thickness: 0px;
            font-size: calc($aboutMeTitle-font-size * 0.2);
            opacity: 40%;
        }
    }

    &__contents {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        // background-color: aqua;
        // opacity: 90%;
        overflow-y: hidden;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -webkit-scroll-snap-type: x mandatory;

        //background stuff
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        background-attachment: fixed;
        background-image: url("../../assets/images/pastelpurple.jpg");

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        &__carousel {
            display: flex;
            justify-content: center;
            // background-color: palegreen;
            width: 300%;
            height: 100%;

            &__page {
                scroll-snap-align: start;
                width: 100vw;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top;
                background-attachment: fixed;

                // &--skills {
                //     background-color: brown; //css struggling to render background image, hence the glitching
                //     background-image: url("../../assets/images/blackblu.jpg");
                // }

                // &--description {
                //     background-color: black;
                //     background-image: url("../../assets/images/pastelpurple.jpg");
                // }

                // &--techstack {
                //     background-color: red;
                //     background-image: url("../../assets/images/ultraviolet.jpg");
                // }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .about-me {
        &__title {
            &__prompt {
                font-size: calc($aboutMeTitle-font-size * 0.2);
            }
        }
    }
}

@media screen and (min-width: 991px) {
    .about-me {
        &__title {
            &__prompt {
                font-size: calc($aboutMeTitle-font-size * 0.5);
            }
        }
    }
}
