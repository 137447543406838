@import "../../assets/styles/styles.scss";

// My Projects Section  Components
.my-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    overflow-y: hidden;
    scroll-snap-align: start;

    background-image: url("../../assets/images/deepdark.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;

    &__title {
        font-size: $aboutMeTitle-font-size;
        color: $color-title;
        font-weight: 200;
        text-decoration: underline;
        text-decoration-thickness: 1px;
    }

    &__contents {
        // position: absolute;
        margin: 0;
        z-index: 1;
        width: 100%;
        height: calc(100vh - ($aboutMeTitle-font-size * 2));
        // background-color: aqua;
        // opacity: 50%;
        overflow: hidden;

        &__h-container {
            margin-bottom: 100px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            overflow-y: hidden;
            overflow-x: auto;
            align-items: center;
            border-radius: 10px;
            z-index: 1;
            scroll-snap-type: x mandatory;
            // -webkit-backdrop-filter: blur(10px);
            // backdrop-filter: blur(10px);

            &::-webkit-scrollbar {
                display: none;
            }

            &::-webkit-scrollbar-thumb {
                display: none;
            }
        }
    }
}
