@import "../../../assets/styles/styles.scss";

.projectCard {
    cursor: pointer;
    transition: 200ms;
    // background-color: $color-title;
    // border: 1px black solid;
    width: 250px;
    height: 230px;
    border-radius: 15px;
    border: 0.25px solid rgba(0, 0, 0, 0.377);
    display: flex;
    //Testing the styling and stuff
    // background-image: url("../../../assets/images/Snake.png");
    background-size: cover;
    background-repeat: no-repeat;

    &__container {
        transition: 100ms;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);

        &--hovered {
            transition: 100ms;
            -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
        }

        &__title {
            font-size: 40px;
            color: black;
            font-weight: 200;
            width: 100%;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 5px;
            // background-color: red;
        }

        &__info {
            height: 100%;
            opacity: 0%;

            &__description {
                padding-top: 10px;
                padding-bottom: 5px;
                overflow: hidden;
                height: 100px;
                font-style: italic;
                width: 95%;
                padding-left: 5px;
                // background-color: aqua;
                font-size: 14px;
                line-height: 1.5em;
                font-weight: 300;
                text-align: center;
            }

            &__links {
                // background-color: brown;
                opacity: 70%;
                width: 230px;
                height: 70px;
                overflow: hidden;
                display: flex;

                &__item {
                    transition: 300ms;
                    width: 50%;
                    display: grid;
                    justify-items: center;
                    align-items: center;
                    font-size: 50px;
                    line-height: 0.4em;
                    :hover {
                        transition: 300ms;
                        transform: scale(1.05);
                    }

                    &__svg {
                        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.06);
                    }

                    &__text {
                        pointer-events: none;
                        font-size: 12px;
                        align-self: flex-end;
                    }
                }
            }

            &__tools {
                position: relative;
                width: 20%;
                // background-color: pink;
                color: white;
                display: grid;
                height: 80%;
                bottom: 190px;
                left: 255px;
                grid-template-rows: 1fr 1fr 1fr 1fr;
                font-size: 35px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .projectCard {
        margin-left: calc($navBar-width * 0.3);
    }
}

.projectLink {
    transition: 200ms;
    color: black;
    text-decoration: none;
}

.projectLink:hover {
    transition: 200ms;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.projectLink:visited {
    transition: 200ms;
    color: inherit;
    cursor: pointer;
}

.darkMode {
    color: white;
}

.lightMode {
    color: black;
}
