@import "../../../assets/styles/styles.scss";

.description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    // -webkit-backdrop-filter: blur($section-blur);
    // backdrop-filter: blur($section-blur);
    // background-color: bisque;

    &__top {
        height: $aboutMeTitle-font-size;
        padding: 10px 0 5px 0;
        // background-color: aquamarine;
        width: 100%;
    }

    &__text {
        line-height: 1.6;
        width: max(80%, 250px);
        height: calc(100% - (2 * $aboutMeTitle-font-size));
        // background-color: brown;
        padding: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 5vmin;

        &__paragraph {
            width: 99%;
            color: $color-title;
            font-weight: 300;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 90%;
            font-size: inherit;
            padding-bottom: 10px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            font-style: italic;
            text-align: center;
            // -webkit-text-fill-color: transparent;
            // -webkit-background-clip: text;
            // background-clip: text;
            // background-image: url("../../../assets/images/rainbow.jpg");
            // background-size: cover;
            // background-repeat: no-repeat;
            // background-position: top;
            // background-attachment: fixed;
        }
    }
}

@media screen and (min-width: 300px) {
    .description {
        &__text {
            font-size: 4.5vmin;
        }
    }
}

@media screen and (min-width: 481px) {
    .description {
        &__text {
            font-size: 3.4vmin;
        }
    }
}

@media screen and (min-width: 600px) {
    .description {
        &__text {
            font-size: 3.2vmin;
        }
    }
}

@media screen and (min-width: 769px) {
    .description {
        &__text {
            font-size: 3.4vmin;
        }
    }
}

@media screen and (min-width: 991px) {
    .description {
        &__text {
            font-size: 3.5vmin;
        }
    }
}
