@import "../../assets/styles/styles.scss";

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    height: 100vh;
    scroll-snap-align: start;

    background-image: url("../../assets/images/deepdark.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;

    &__title {
        cursor: pointer;
        margin-top: 10%;
        font-size: 11vw;
        color: $color-title;
        opacity: 85%;
        font-weight: 200;
        text-align: center;

        &__arrow {
            opacity: 0;
        }
    }
}
