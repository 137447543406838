@import "../../assets/styles/styles.scss";

.navbar {
    position: fixed;
    overflow: scroll;
    transition: 600ms ease;
    z-index: 4;
    &::-webkit-scrollbar {
        display: none;
    }
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    &::-webkit-scrollbar-thumb {
        display: none;
    }
    cursor: pointer;
}

.navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-around;

    .nav-item {
        width: 100%;
    }
}

.nav-link {
    display: flex;
    align-items: center;
    height: 5rem;
    color: $color-title;
    text-decoration: none;
    font-weight: 300;

    transition: 600ms ease;

    &:hover {
        filter: grayscale(0%) opacity(1);
        color: $color-title;

        .fa-primary {
            color: rgb(108, 108, 226);
        }
    }

    svg {
        width: 2rem;
        min-width: 2rem;
        margin: 0 1.5rem;
        color: $color-title;
    }
    .link-text {
        display: none;
        margin-left: 1rem;
    }
}

.navbar:hover {
    .logo svg {
        transform: rotate(-180deg);
        color: $color-title;
    }
}

.fa-group {
    * {
        transition: 600ms ease;
    }

    .fa-primary {
        color: $color-title;
    }

    .fa-secondary {
        color: $color-title;
        transition: 600ms ease;
    }
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: $color-title;
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;

    svg {
        transform: rotate(0deg);
        transition: 600ms ease;
    }

    .logo-text {
        display: inline;
        position: absolute;
        left: -999px;
        transition: 550ms ease;
    }
}

/* Small screens */
@media screen and (max-width: 767px) {
    .navbar {
        bottom: 0;
        width: 100vw;
        height: $aboutMeTitle-font-size;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);

        .navbar-nav {
            flex-direction: row;
            width: 100vw;

            .logo {
                display: none;
            }
        }
    }

    .nav-link {
        justify-content: center;
    }

    .main {
        margin-left: 0px;
    }
}

/* Large screens */
@media screen and (min-width: 768px) {
    .navbar {
        top: 0;
        width: 5rem;
        height: 100vh;

        &:hover {
            width: $navBar-width;

            .link-text {
                display: inline;
            }

            .logo svg {
                margin-left: 11rem;
            }

            .logo-text {
                left: 0px;
            }
        }
    }
}

.notCurrent {
    filter: grayscale(100%) opacity(0.5);
}

.current {
    filter: grayscale(0%) opacity(1);
    .fa-primary {
        color: rgb(108, 108, 226);
    }
}
