@import "../../assets/styles/styles.scss";
.contact-me {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    height: 100vh;
    overflow-y: hidden;
    scroll-snap-align: start;

    background-image: url("../../assets/images/pastelpurple.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;
    scroll-snap-align: start;

    &__title {
        font-size: $aboutMeTitle-font-size;
        color: $color-title;
        font-weight: 200;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        // background-color: aqua;
    }

    &__content {
        // width: 100%;
        // background-color: bisque;
        height: 70%; // will have to turn off when nav is extended
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;

        &__portrait {
            // background-color: aqua;
            background-image: url(https://media-exp1.licdn.com/dms/image/C5603AQFBpHY5KyN01w/profile-displayphoto-shrink_800_800/0/1644245489370?e=1655942400&v=beta&t=MoNSUjr__ovUX4dl2v7Lv71S2f2ydmIhHVgdisyiyVk);
            background-size: contain;
            width: 20vmin;
            height: 20vmin;
            border-radius: 100%;
        }

        &__text {
            font-size: 5.5vmin;
            text-align: center;
            font-weight: 250;
            font-style: italic;
            // background-color: red;
            color: $color-title;
            line-height: 2em;
            width: 74%;
            // margin-left: $navBar-width;
        }

        &__symbol-container {
            // background-color: blue;
            font-size: 13vmin;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
            padding-top: 4%;
            width: 65%;
            color: white;

            &__link {
                transition: 400ms;
                &:hover {
                    transform: scale(1.2);
                    transition: 400ms;
                }
            }
        }

        &__copyright {
            color: white;
            position: relative;
            top: 24%;
            height: 1em;
            font-weight: 200;
            font-size: 2.4vmin;
            margin: 0 auto;
        }
    }
}
