@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$color-title: white;
$color-hover: #6c6ce2;
$navBar-width: 16rem;
$section-blur: 0px;
$aboutMeTitle-font-size: 11vmin;

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: $color-title;
}

$main-font: "Roboto", sans-serif;

* {
    font-family: $main-font;
    margin: 0px;
    padding: 0px;
}
