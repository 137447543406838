.skill {
    width: 17vmin;
    height: 17vmin;
    // background-color: cornflowerblue;

    &__outer {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: rgba(233, 0, 0, 0.507);

        &__inner {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
            // display: flex;
            // justify-content: center;
            // align-items: center;

            &__icon {
                color: rgba(255, 255, 255, 0.86);
                font-size: 8vmin;
                opacity: 70%;
            }

            &__text {
                // position: absolute;
                margin-top: 5%;
                color: rgba(255, 255, 255, 0.828);
                // text-transform: uppercase;
                // font-style: italic;
                font-weight: 300;
                font-size: 2.8vmin;
            }
        }
    }

    &__svg {
        display: none;
        position: absolute;
        top: 100px;
        fill: none;
        stroke-width: 5;
        stroke-dasharray: 472;
        stroke-dashoffset: 472; //Change this value to change the percentage fullness
        // animation: anim 10s linear forwards; gsap will probably animate
    }
}

@media screen and (min-width: 560px) {
    .skill {
        &__outer {
            &__inner {
                &__text {
                    font-weight: 300;
                    font-size: 2.3vmin;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (min-height: 650px) {
    .skill {
        width: 160px;
        height: 160px;

        &__outer {
            border: none;
            position: absolute;
            background-color: unset;
            width: 160px;
            height: 160px;
            border-radius: 50%;
            // border: 1px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &__inner {
                opacity: initial;
                height: 120px;
                width: 120px;
                border-radius: 50%;
                background-image: none;
                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);

                &__icon {
                    color: white;
                    font-size: 60px;
                    opacity: 70%;
                }

                &__text {
                    opacity: 0;
                    width: 70%;
                    display: none;
                    position: absolute;
                    // text-transform: uppercase;
                    // font-style: italic;
                    left: 20vmin;
                    top: initial;
                    color: white;
                    font-weight: 200;
                    font-size: 2.3vmin;
                }
            }
        }

        &__svg {
            display: initial;
            position: absolute;
            top: 100px;
            fill: none;
            stroke-width: 5;
            stroke-dasharray: 472;
            stroke-dashoffset: 472; //Change this value to change the percentage fullness
            // animation: anim 10s linear forwards; gsap will probably animate
        }
    }
}

@media screen and (min-width: 1081px) and (min-height: 650px) {
    .skill {
        &__outer {
            &__inner {
                cursor: initial;
                &__text {
                    left: 21vmin;
                    opacity: 100%;
                    font-weight: 200;
                    font-size: 2.8vmin;
                    display: initial;
                }

                &__textHidden {
                    opacity: 0;
                    width: 70%;
                    display: none;
                    position: absolute;
                    // text-transform: uppercase;
                    // font-style: italic;
                    left: 20vmin;
                    top: initial;
                    color: white;
                    font-weight: 200;
                    font-size: 2.3vmin;
                }
            }
        }
    }
}
